@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
}

.loginpage {
  text-align: center;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.react-flow-panel {
  height: 500px !important;
  background-color: rgb(13, 140, 231);
  border-radius: 5px;
}

.aa-Form {
  background: transparent !important;
}

.aa-Input {
  color: gray !important;
}
.custom-autocomplete {
  width: 100%;
}
.aa-Item {
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 5px; */
  padding: 0px;
}
.aa-Panel {
  background-color: transparent !important;
}

.aa-Panel--scrollable {
  scrollbar-color: dark !important;
}
